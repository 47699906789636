<template>
  <v-row>
    <v-col cols="12" md="6">
      <select-warehouse-model
        v-model="id_warehouse"
        :label="$t('labels.warehouse')"
        class="mb-5"
        dense
        outlined
        clearable
        hide-details
        @onFilter="onWarehouseChange"
      ></select-warehouse-model>

      <input-qr-scan-model
        ref="inputQr_1"
        v-model="code"
        class="mb-5"
        :label="$t('labels.sku_barcode_sub_barcode')"
        @keyupEnter="getGoods"
      ></input-qr-scan-model>

      <v-autocomplete
        v-if="customerGoodsOptions.length > 0"
        v-model="id_goods"
        :items="customerGoodsOptions"
        :disabled="!id_warehouse"
        class="c-input-xs mb-5"
        :error="validates.includes('id_goods')"
        ref="inputCustomer"
        :label="$t('labels.customer')"
        :placeholder="$t('labels.customer')"
        dense
        outlined
        hide-details
        @change="onCustomerChange"
      ></v-autocomplete>

      <input-qr-scan-model
        ref="inputQr_2"
        v-model="cell_id"
        class="mb-5"
        :label="$t('labels.scan_position_to_confirm')"
        @keyupEnter="setLocation"
      ></input-qr-scan-model>

      <!-- <v-text-field v-model="code" :disabled="!id_warehouse" class="c-input-xs mb-5" ref="inputIndex_1"
        :error="validates.includes('code')" :label="$t('labels.sku_barcode_sub_barcode')"
        :placeholder="$t('labels.sku_barcode_sub_barcode')" @keyup.enter="getGoods" dense outlined clearable
        hide-details></v-text-field> -->

      <!-- <v-text-field v-model="cell_id" :disabled="!id_warehouse || !id_goods" class="c-input-xs mb-5" ref="inputIndex_2"
        :error="validates.includes('cell_id')" :label="$t('labels.scan_position_to_confirm')"
        :placeholder="$t('labels.scan_position_to_confirm')" @keyup.enter="setLocation" dense outlined clearable
        hide-details></v-text-field> -->

      <small v-if="errMsg" class="red--text mb-5">{{ errMsg }}</small>

      <v-alert color="primary" text>
        <div class="mb-2">
          {{ $t("labels.available_position_closest") }}
        </div>
        <v-text-field
          v-model="current_cell_id"
          class="c-input-xs mb-5"
          :disabled="!id_warehouse"
          :label="$t('labels.scan_position_standing')"
          :placeholder="$t('labels.scan_position_standing')"
          :error="validates.includes('current_cell_id')"
          @keyup.enter="suggestByCell"
          dense
          outlined
          clearable
          hide-details
        ></v-text-field>

        <div class="mb-5" v-if="suggest_cells.length > 0">
          {{ $t("labels.suggest") }}:
          <span
            style="font-size: 16px; font-weight: 600; color: red"
            v-for="c in suggest_cells"
            :key="`${c.cell_id}_${c.indexing}`"
          >
            {{ c.cell_id }};
          </span>
        </div>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LocationSet",
  components: {},
  data: () => ({
    id_warehouse: null,
    id_goods: null,
    current_cell_id: null,
    cell_id: null,
    type: null,
    code: null,
    errMsg: null,

    suggest_cells: [],
    goods_customers: [],

    validates: [],

    dialogConfirm: false,
    isLoading: false,

    typeOptions: [],
  }),
  computed: {
    customerGoodsOptions() {
      return this.goods_customers.map((g) => ({
        text: `${g.company_name} (SL: ${g.available} - Vị trí: ${
          g.storage_location || ""
        })`,
        value: g.id,
      }));
    },
  },
  created() {
    const typeOptions = [
      {
        value: null,
        text: this.$t("labels.find_by_all"),
      },
      {
        value: "sku",
        text: this.$t("labels.sku"),
      },
      {
        value: "customer_goods_barcode",
        text: this.$t("labels.barcode"),
      },
      {
        value: "sub_barcode",
        text: this.$t("labels.sub_barcode"),
      },
    ];

    this.typeOptions = [...typeOptions];
  },
  methods: {
    nextInputFocus(index) {
      if (index > 10) {
        return false;
      }
      this.$nextTick(() => {
        if (this.$refs[`inputIndex_${index + 1}`]) {
          this.$refs[`inputIndex_${index + 1}`].focus();
        } else {
          this.nextInputFocus(index + 1);
        }
      });
    },

    onResetAll() {
      this.current_cell_id = null;
      this.suggest_cells = [];
      this.code = null;
      this.id_goods = null;
      this.cell_id = null;
    },

    onWarehouseChange() {
      this.onResetAll();
      setTimeout(() => {
        this.$refs.inputQr_1.focusInput();
      }, 100);
    },

    onCustomerChange() {
      setTimeout(() => {
        this.$refs.inputQr_2.focusInput();
      }, 100);
    },

    async suggestByCell() {
      try {
        const { data } = await httpClient.post(
          "/warehouse-location/v1/suggest-by-cell",
          {
            id_warehouse: this.id_warehouse,
            cell_id: this.current_cell_id,
          }
        );
        this.suggest_cells = [...data];
        this.current_cell_id = null;
        document.getElementById("success_sound_player").play();
        this.nextInputFocus(2);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },

    async getGoods() {
      if (!this.code) {
        return false;
      }
      try {
        const codeSpl = this.code.split("@");
        const sku = codeSpl[0] || "";
        const { data } = await httpClient.post(
          "/goods/v1/get-by-code-type-and-warehouse",
          {
            id_warehouse: this.id_warehouse,
            code: sku,
            type: this.type,
          }
        );
        this.goods_customers = [...data];
        document.getElementById("success_sound_player").play();
        if (data.length === 1) {
          this.id_goods = data[0].id;
          setTimeout(() => {
            this.$refs.inputQr_2.focusInput();
          }, 100);
        } else {
          setTimeout(() => {
            this.$refs.inputCustomer.focus();
          }, 100);
        }
      } catch (e) {
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },

    async setLocation() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.isLoading = true;
      this.errMsg = null;
      this.validates = [];
      try {
        await httpClient.post("/warehouse-location/v1/set-location", {
          id_warehouse: this.id_warehouse,
          id_goods: this.id_goods,
          cell_id: this.cell_id,
        });
        this.isLoading = false;
        this.onResetAll();
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        this.cell_id = null;
        this.errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        /* const validates = e.response && e.response.data && e.response.data.error && e.response.data.error.validates || []
        if (validates && validates.length > 0) {
          validates.forEach(v => {
            this.validates.push(v.key)
          })
        } */
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
